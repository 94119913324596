<template>
  <b-row class="mt-5">
    <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
      <b-card no-body>
        <b-card-header>
          <b-col
            md="12"
            v-if="errors.length > 0"
          >
            <b-alert
              variant="danger"
              :show="errors.length > 0"
            >
              <strong>Ein Fehler ist aufgetreten!</strong>
              <ul v-if="errors.filter(err => err.field).length > 0">
                <li
                  v-for="(error, index) in errors.filter(err => err.field)"
                  :key="index"
                >
                  <strong></strong>{{ error.message }}
                </li>
              </ul>
            </b-alert>
          </b-col>
          <b-col md="12">
            <p>Hallo {{ firstName }} {{ lastName }},</p>
            <p></p>
            <p>
              am <b>{{ startDate }}</b> nehmen Sie am Seminar
              <b>{{ seminarTitle }}</b> der GFU Cyrus AG<b v-if="isRemote"> remote</b>
              teil. Damit wir Ihnen die Unterlagen, Teilnahmezertifikat und
              weitere Materialien für Ihre Schulung zuschicken können, benötigen
              wir noch die richtige Versandadresse.
            </p>
            <p></p>
            <p>
              Ihre eingegebenen Daten werden nur zu diesem Zweck genutzt und
              nach der Durchführung der Schulung aus unseren Systemen gelöscht.
            </p>
            <p
              class="popover-subsitute"
              v-if="selectedForm === 'company'"
            >
              Sofern Sie die Unterlagen z.B. in ein Coworking Space oder eine andere Niederlassung senden lassen
              möchten, können Sie die unten stehenden Daten einfach überschreiben.
            </p>
          </b-col>
          <b-col
            md="12"
            v-if="dataReceived === false"
          >
            <b-row>
              <b-col
                lg="5"
                md="6"
                class="mb-4"
              >
                <b-card
                  no-body
                  class="h-100 selectable"
                  :class="{ selected: selectedForm === 'company' }"
                  @click="expandedForm = true; selectedForm = 'company';"
                >
                  <b-card-header>
                    <strong>Firmenadresse verwenden</strong>
                  </b-card-header>
                  <b-card-body>
                    {{ companyAddress.company }}<br />
                    {{ firstName }} {{ lastName }}<br />
                    {{ companyAddress.street }}<br />
                    {{ companyAddress.zipCode }} {{ companyAddress.city }}<br />
                    {{ companyAddress.country }}
                    <div
                      class="text-right text-link"
                      v-if="!expandedForm"
                    >
                      <a>ändern <b-icon icon="pencil"></b-icon></a>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col
                lg="5"
                md="6"
                class="mb-4"
              >
                <b-card
                  class="h-100 selectable"
                  :class="{ selected: selectedForm === 'private' }"
                  no-body
                  @click="expandedForm = true; selectedForm = 'private';"
                >
                  <b-card-header>
                    <strong>Private Adresse verwenden</strong>
                    <small v-if="isParcelLocker"><br>Packstation</small>
                  </b-card-header>
                  <b-card-body :class="{
                    'text-center':
                      selectedForm === 'company' &&
                      Object.keys(privateAddress).length === 1,
                  }">
                    <b-icon
                      icon="plus"
                      font-scale="4"
                      class="mt-3"
                      v-if="selectedForm === 'company' &&
                        Object.keys(privateAddress).length === 1
                      "
                    ></b-icon>
                    <div v-else>
                      {{ firstName }} {{ lastName }}<br />
                      <span v-if="privateAddress.company || isParcelLocker">{{ isParcelLocker ? 'Postnummer: ' : '' }}{{
                        privateAddress.company }}<br /></span>
                      {{ isParcelLocker ? 'Packstations-Nr: ' : '' }}{{ privateAddress.street }} <br />
                      {{ privateAddress.zipCode }} {{ privateAddress.city }}<br />
                      {{ privateAddress.country }}
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-card-header>
        <b-card-body>
          <b-col
            md="12"
            v-if="dataReceived === false"
          >
            <b-form
              class="address-form row"
              @submit.prevent="saveAddress()"
            >
              <b-col
                md="12"
                v-if="expandedForm"
              >
                <b-form-group
                  id="input-group-0"
                  class="mb-3 cursor-pointer"
                >
                  <b-form-radio-group
                    v-model="selectedAddressType"
                    :options="addressTypeOptions"
                    name="address-type-options"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                v-if="expandedForm"
              >
                <b-form-group
                  id="input-group-1"
                  label-for="input-1"
                  class="mb-3"
                >
                  <template slot="label"> Name <span class="font-weight-bold">*</span></template>
                  <b-form-input
                    id="input-1"
                    v-model="studentAddress.name"
                    type="text"
                    readonly
                    disabled
                    class="disabled"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                v-if="expandedForm && selectedAddressType === 'homeAddress'"
              >
                <b-form-group
                  id="input-group-2"
                  label-for="input-2"
                  class="mb-3"
                >
                  <template
                    slot="label"
                    v-if="selectedForm === 'company'"
                  >Firma <span class="font-weight-bold">*</span></template>
                  <template
                    slot="label"
                    v-else
                  >Zusatz, c/o usw. <small class="text-muted">(optional)</small></template>
                  <b-form-input
                    id="input-2"
                    v-model="studentAddress.company"
                    maxlength="100"
                    type="text"
                    :required="selectedForm === 'company'"
                    tabindex="0"
                  ></b-form-input>
                </b-form-group>
                <div class="d-none d-sm-block">
                  <b-popover
                    target="input-2"
                    placement="right"
                    triggers="focus"
                    v-if="selectedForm === 'company'"
                  >
                    <template #title>Firmenadresse anpassen</template>
                    Sofern Sie die Unterlagen z.B. in ein Coworking Space oder eine andere Niederlassung senden lassen
                    möchten, können
                    Sie die Firmenadresse einfach
                    überschreiben.
                  </b-popover>
                  <b-popover
                    target="input-2"
                    placement="right"
                    triggers="focus"
                    v-else
                  >
                    <template #title>Adresse anpassen</template>
                    Hier können Sie ein Coworking Space oder einen Adresszusatz angeben
                  </b-popover>
                </div>
              </b-col>
              <b-col
                md="12"
                v-if="expandedForm && selectedAddressType === 'parcelLocker'"
              >
                <b-form-group
                  id="input-group-2"
                  label-for="input-2"
                  class="mb-3"
                >
                  <template slot="label">Postnummer / Kundennummer <span class="font-weight-bold">*</span></template>
                  <b-form-input
                    id="input-2"
                    v-model="studentAddress.company"
                    type="text"
                    :required="true"
                    tabindex="0"
                  ></b-form-input>
                </b-form-group>
                <div class="d-none d-sm-block">
                  <b-popover
                    target="input-2"
                    triggers="focus"
                    placement="right"
                  >
                    <template #title>Postnummer</template>
                    Tragen Sie in diesem Feld Ihre persönliche Kundenummer (auch Postnummer genannt) ein. Diese finden
                    Sie auf Ihrer
                    DHL Kundenkarte oder in der Post & DHL App.
                  </b-popover>
                </div>
              </b-col>
              <b-col
                md="12"
                v-if="expandedForm"
              >
                <b-form-group
                  id="input-group-3"
                  label="Straße und Hausnummer"
                  label-for="input-3"
                  class="mb-3"
                >
                  <template slot="label">{{ selectedAddressType === 'homeAddress' ? 'Straße und Hausnummer' :
                    'Packstations-Nr.' }}<span class="font-weight-bold"> *</span></template>
                  <b-form-input
                    id="input-3"
                    v-model="studentAddress.street"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                v-if="expandedForm"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      id="input-group-4"
                      label="Postleitzahl"
                      label-for="input-4"
                      class="mb-3"
                    >
                      <template slot="label"> Postleitzahl <span class="font-weight-bold">*</span> </template>
                      <b-form-input
                        id="input-4"
                        v-model="studentAddress.zipCode"
                        type="text"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="8">
                    <b-form-group
                      id="input-group-5"
                      label="Ort"
                      label-for="input-5"
                      class="mb-3"
                    >
                      <template slot="label"> Ort <span class="font-weight-bold">*</span></template>
                      <b-form-input
                        id="input-5"
                        v-model="studentAddress.city"
                        type="text"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="12"
                v-if="expandedForm && selectedAddressType === 'homeAddress'"
              >
                <b-form-group
                  id="input-group-6"
                  label=""
                  label-for="input-6"
                  class="mb-3"
                >
                  <template slot="label">Land <small class="text-muted">(optional)</small></template>
                  <b-form-input
                    id="input-6"
                    v-model="studentAddress.country"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                v-if="expandedForm"
                class="mb-4"
              >
                <b-form-group role="group">
                  <template slot="label">
                    E-Mail-Adresse zur Paketverfolgung <small class="text-muted">(optional)</small>
                  </template>
                  <b-form-input
                    id="input-7"
                    v-model="studentAddress.email"
                    type="text"
                  ></b-form-input>
                  <b-popover
                    target="input-7"
                    placement="right"
                    triggers="focus"
                  >
                    <template #title>Möchten Sie ihr Paket verfolgen?</template>
                    Sofern Sie Informationen zur Zustellung Ihres Pakets mit den Schulungsunterlagen per E-Mail durch
                    DHL erhalten
                    möchten, tragen Sie bitte Ihre E-Mail-Adresse ein. Wir verwenden die hier angegebene E-Mail-Adresse
                    nur einmalig
                    und nur zu diesem Zweck. Mit der Angabe Ihrer E-Mail-Adresse erklären Sie sich mit einer Weitergabe
                    an DHL
                    einverstanden.
                  </b-popover>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <p>
                  In Ihrem „Brainfood-Paket“ sind neben Schulungsunterlagen auch
                  „Brainboosting“-Snacks und -Gimmicks enthalten. Wenn es Ihnen
                  lieber ist, dass wir Ihnen nur die Schulungsunterlagen aber
                  keine „Brainboosting“-Snacks und -Gimmicks zuschicken, dann
                  deaktivieren Sie bitte die folgende Option.
                </p>
                <div>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="snacks"
                    name="checkbox-1"
                    unchecked-value="0"
                    value="1"
                    class="mr-3 mb-3 cursor-pointer"
                    switch
                  >
                    &nbsp;Ich möchte<b>{{ (parseInt(snacks) === 0) ? ' keine' : '' }} Snacks und Gimmicks</b> erhalten
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col>
                <div class="clearfix">
                  <b-button
                    class="float-right btn-cta"
                    type="submit"
                    variant="primary"
                  >
                    Abschicken
                  </b-button>
                </div>
              </b-col>
            </b-form>
          </b-col>

          <b-row v-if="dataReceived === true">
            <b-col
              class="text-center mt-3 mb-5"
              md="12"
            >
              <p class="text-success mb-5"><strong>Wir haben Ihre Daten erhalten. Dankeschön.</strong></p>
              <p><b>Versandadresse:</b></p>
              <p>
                {{ studentAddress.name }}
                <span v-if="studentAddress.company"><br>{{ isParcelLocker ? 'Postnummer: ' :
                  '' }}{{ studentAddress.company }}</span><br>
                {{ isParcelLocker ? 'Packstations-Nr.: ' : '' }}{{ studentAddress.street }}<br>
                {{ studentAddress.zipCode }} {{ studentAddress.city }}
                <span v-if="studentAddress.country"><br>{{ studentAddress.country }}</span><br>
                <span v-if="studentAddress.email">{{ studentAddress.email }}</span>
              </p>
              <p><b>„Brainboosting“-Snacks:</b></p>
              <p>
                <strong
                  class="text-success"
                  v-if="parseInt(snacks) === 1"
                ><b-icon
                    icon="check-circle-fill"
                    style="font-weight:bolt"
                  ></b-icon> Ja</strong>
                <strong
                  class="text-danger"
                  v-else
                ><b-icon icon="x-circle-fill"></b-icon> Nein</strong>
              </p>
            </b-col>
            <b-col
              md="12"
              class="text-right "
            >
              <b-button
                type="default"
                @click="dataReceived = false"
              ><b-icon
                  icon="arrow-left"
                  style="margin-bottom:-1.5px"
                ></b-icon>&nbsp;zurück zum Formular</b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </b-row>
</template>

<script>
import axios from "axios";

export default {
  name: "AddressForm",
  data() {
    return {
      bookingHashId: null,
      booking: null,
      studentAddress: {},
      snacks: 1,
      privateAddress: {},
      companyAddress: {},
      success: null,
      errors: [],
      selectedForm: "company",
      selectedAddressType: "homeAddress",
      addressTypeOptions: [
        { text: 'An Hausadresse senden', value: 'homeAddress' },
        { text: 'An Packstation senden', value: 'parcelLocker' },
      ],
      showCompanyPopover: true,
      dataReceived: false,
      expandedForm: false,
    };
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.bookingHashId = urlParams.get("bidh");
    if (this.bookingHashId) {
      axios
        .get(
          `https://api.gfu.cloud/api/student-addresses/${this.bookingHashId}/by-hash`
        )
        .then((resp) => {
          this.booking = resp.data;
          // console.log("this.booking", this.booking);
          this.studentAddress = {
            ...this.studentAddress,
            name: this.firstName + " " + this.lastName,
            company: this.companyName,
            street: this.companyStreet,
            zipCode: this.companyZipCode,
            city: this.companyCity,
          };

          this.setCompanyAddress();
          this.privateAddress.name = this.studentAddress.name;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.errors.push({
              field: 'hash',
              message: error.response.data.message
            });
          } else {
            this.errors = [error];
          }
        });
    }
  },
  methods: {
    saveAddress() {
      this.studentAddress.company = (this.studentAddress.company) ? this.studentAddress.company : null;
      this.studentAddress.country = (this.studentAddress.country) ? this.studentAddress.country : null;

      axios
        .post(
          `https://api.gfu.cloud/api/student-addresses/${this.bookingHashId}/by-hash`,
          {
            ...this.studentAddress,
            street: (this.selectedAddressType === 'parcelLocker') ? 'Packstation-Nr.: ' + this.studentAddress.street : this.studentAddress.street,
            company: (this.selectedAddressType === 'parcelLocker') ? 'Postnummer: ' + this.studentAddress.company : this.studentAddress.company,
            snacks: parseInt(this.snacks),
            combit_id: null
          },
        )
        .then(() => {
          this.success = true;
          this.errors = [];
          this.dataReceived = true;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            this.errors = error.response.data;
          } else {
            this.errors = [error];
          }
          window.scrollTo(0, 0);
          this.success = false;
        });

      return true;
    },
    dateToLocalString(dateString) {
      const startDate = new Date(dateString);
      return startDate.toLocaleDateString("de-DE", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    },
    setStudentAddress(address) {
      this.studentAddress = address;
    },
    setCompanyAddress() {
      this.companyAddress = this.studentAddress;
    },
    setPrivateAddress() {
      this.privateAddress = this.studentAddress;
    },
  },
  computed: {
    isParcelLocker() {
      return this.selectedAddressType === 'parcelLocker' || this.wasParcelLocker;
    },
    isRemote() {
      return (
        this.booking &&
        this.booking.seminarDate &&
        this.booking.seminarDate.location === "Online"
      );
    },
    salutation() {
      return this.booking && this.booking.contact.gender
        ? this.booking.contact.gender === "m"
          ? "Herr"
          : "Frau"
        : "";
    },
    firstName() {
      return this.booking && this.booking.contact
        ? this.booking.contact.firstName
        : "____";
    },
    lastName() {
      return this.booking && this.booking.contact
        ? this.booking.contact.lastName
        : "____";
    },
    startDate() {
      return this.booking && this.booking.seminarDate
        ? this.dateToLocalString(this.booking.seminarDate.start)
        : "____";
    },
    seminarTitle() {
      return this.booking &&
        this.booking.seminarDate &&
        this.booking.seminarDate.seminar
        ? this.booking.seminarDate.seminar.title
        : "____";
    },
    companyName() {
      return this.booking &&
        this.booking.contact &&
        this.booking.contact.company
        ? this.booking.contact.company.company
        : "____";
    },
    companyName2() {
      return this.booking &&
        this.booking.contact &&
        this.booking.contact.company
        ? this.booking.contact.company.company2
        : "____";
    },
    companyStreet() {
      return this.booking &&
        this.booking.contact &&
        this.booking.contact.company
        ? this.booking.contact.company.street
        : "____";
    },
    companyZipCode() {
      return this.booking &&
        this.booking.contact &&
        this.booking.contact.company
        ? this.booking.contact.company.zipCode
        : "____";
    },
    companyCity() {
      return this.booking &&
        this.booking.contact &&
        this.booking.contact.company
        ? this.booking.contact.company.city
        : "____";
    },
    country() {
      return this.booking &&
        this.booking.contact &&
        this.booking.contact.company
        ? this.booking.contact.company.country
        : "___";
    },
  },
  watch: {
    selectedForm(newVal, oldVal) {

      if (oldVal === "company") this.setCompanyAddress();
      if (oldVal === "private") this.setPrivateAddress();

      if (newVal === "company") {
        this.setStudentAddress(this.companyAddress);
        if (this.selectedAddressType === 'parcelLocker') {
          this.wasParcelLocker = true;
        }
        this.selectedAddressType = 'homeAddress';
      }
      if (newVal === "private") {
        if (this.wasParcelLocker === true) {
          this.selectedAddressType = 'parcelLocker';
        }
        this.setStudentAddress(this.privateAddress);
      }
    },
    selectedAddressType(newVal) {
      if (newVal === 'parcelLocker') {
        this.selectedForm = 'private';
      }
      if (this.selectedForm === 'private' && newVal === 'homeAddress') {
        this.wasParcelLocker = false;
      }
    }
  },
};
</script>

<style scoped>
.float-right {
  float: right;
}

.card-primary-light .card-header {
  background: 0 0;
  color: #266cb4;
  border-bottom: 1px solid #ced4da;
}

.btn-cta {
  color: #fff;
  background-color: #f77635;
  border-color: #f77635;
  box-shadow: rgb(108 117 125 / 40%) 3px 3px 10px;
}

.btn-cta:hover,
.btn-cta:active,
.btn-cta:focus {
  color: #fff;
  background-color: #f65d10;
  border-color: #f05709;
  box-shadow: rgb(108 117 125 / 40%) 3px 3px 10px, 0 0 0 0.2rem rgb(248 139 83 / 50%);
}

.card .card {
  box-shadow: none !important;
  padding: 0px;
}

.card .card-header .card .card-header,
.card .card-header .card .card-body {
  padding: 1em;
}

.card.selectable {
  cursor: pointer;
}

.card.selectable.selected,
.card.selectable:hover {
  border-color: #bbb;
}

.card.selectable:hover .card-header {
  background: #266cb4;
  color: white;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  border-top: 1px solid #266cb4;
}

.card.selectable:hover {
  opacity: 0.8;
}

.card.selectable.selected:hover .card-header,
.card.selectable.selected .card-header {
  background: #266cb4;
  color: white !important;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  border-top: 1px solid #266cb4;
}

.card.selectable.selected:hover {
  opacity: 1;
}

.card.selectable .card-header {
  background: #e9ecef;
}

.card.selectable .card-header,
.card.selectable .card-body {
  color: #777;
}

.card.selectable.selected .card-header,
.card.selectable.selected .card-body {
  color: inherit;
}

.popover-header svg {
  margin-right: -5px;
  cursor: pointer;
}

.text-link {
  color: #999;
  position: absolute;
  right: 5px;
  bottom: 1px;
  font-size: 1em;
}

.card.selectable:hover .text-link {
  color: #004c94;
}

@media (max-width: 1330px) {
  .popover.b-popover {
    display: none;
  }
}

@media (min-width: 1331px) {
  .popover-subsitute {
    display: none;
  }
}
</style>

<style>
.cursor-pointer input {
  cursor: pointer;
}

.cursor-pointer label {
  cursor: pointer;
}
</style>